import revive_payload_client_u48j1xwJJd from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AvS9gfCgFC from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9ympJb48Hd from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qSzzL0yfHk from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_CL95JjugvL from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_w94A5zu8XG from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_IfxwStNIQ0 from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/beekk_nuxtjs/.nuxt/components.plugin.mjs";
import prefetch_client_TdWw7OMrm4 from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_pmhhl4yxpaavipcackbq4ong3y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/home/ubuntu/beekk_nuxtjs/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/home/ubuntu/beekk_nuxtjs/.nuxt/element-plus-injection.plugin.mjs";
import bootstrap_client_Uyd73t1ylZ from "/home/ubuntu/beekk_nuxtjs/plugins/bootstrap.client.ts";
import element_plus_HUzmwi0JYR from "/home/ubuntu/beekk_nuxtjs/plugins/element-plus.ts";
import vue_json_viewer_koKP6p2V4D from "/home/ubuntu/beekk_nuxtjs/plugins/vue-json-viewer.ts";
import api_GFfDXud5Cr from "/home/ubuntu/beekk_nuxtjs/plugins/api.ts";
import dayjs_pGiXRjcsJO from "/home/ubuntu/beekk_nuxtjs/plugins/dayjs.ts";
import errorHandler_x1Y4n5NP4w from "/home/ubuntu/beekk_nuxtjs/plugins/errorHandler.ts";
import vue_awesome_paginate_rvI9ZlY63V from "/home/ubuntu/beekk_nuxtjs/plugins/vue-awesome-paginate.ts";
export default [
  revive_payload_client_u48j1xwJJd,
  unhead_AvS9gfCgFC,
  router_9ympJb48Hd,
  payload_client_qSzzL0yfHk,
  navigation_repaint_client_CL95JjugvL,
  check_outdated_build_client_w94A5zu8XG,
  chunk_reload_client_IfxwStNIQ0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TdWw7OMrm4,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  bootstrap_client_Uyd73t1ylZ,
  element_plus_HUzmwi0JYR,
  vue_json_viewer_koKP6p2V4D,
  api_GFfDXud5Cr,
  dayjs_pGiXRjcsJO,
  errorHandler_x1Y4n5NP4w,
  vue_awesome_paginate_rvI9ZlY63V
]